import React from 'react';

const UploadIcon = (props) => {

  return (
    <svg 
      width={props.svgSize}
      height={props.svgSize}
      viewBox="0 0 24 24" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        fill-rule="evenodd" 
        clip-rule="evenodd" 
        d="M11.2929 3.79289C11.6834 3.40237 12.3166 3.40237 12.7071 3.79289L17.2071 8.29289C17.5976 8.68342 17.5976 9.31658 17.2071 9.70711C16.8166 10.0976 16.1834 10.0976 15.7929 9.70711L13 6.91421V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16V6.91421L8.20711 9.70711C7.81658 10.0976 7.18342 10.0976 6.79289 9.70711C6.40237 9.31658 6.40237 8.68342 6.79289 8.29289L11.2929 3.79289ZM4 13C4.55228 13 5 13.4477 5 14V18C5 18.5523 5.44772 19 6 19H18C18.5523 19 19 18.5523 19 18V14C19 13.4477 19.4477 13 20 13C20.5523 13 21 13.4477 21 14V18C21 19.6569 19.6569 21 18 21H6C4.34315 21 3 19.6569 3 18V14C3 13.4477 3.44772 13 4 13Z" 
        fill={props.svgColor}
      />
    </svg>
  )
};

export default UploadIcon;
import React from 'react';

const BlockIcon = (props) => {

  return (
    <svg 
      width={props.svgSize}
      height={props.svgSize}
      viewBox="0 0 24 24" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd" 
        clip-rule="evenodd" 
        d="M12.4061 4.27479C12.1476 4.15987 11.8524 4.15987 11.5939 4.27479L5.46221 6.99996L12 9.90565L18.5378 6.99996L12.4061 4.27479ZM20 8.53873L13 11.6498V19.4612L19.4061 16.614C19.7673 16.4535 20 16.0954 20 15.7002V8.53873ZM11 19.4612V11.6498L4 8.53873V15.7002C4 16.0954 4.23273 16.4535 4.59386 16.614L11 19.4612ZM10.7816 2.44716C11.5573 2.10241 12.4427 2.10241 13.2184 2.44716L21.4061 6.08615C21.7673 6.24665 22 6.60477 22 6.99996V15.7002C22 16.8858 21.3018 17.9601 20.2184 18.4417L12.4061 21.9138C12.1476 22.0287 11.8524 22.0287 11.5939 21.9138L3.78158 18.4417C2.6982 17.9601 2 16.8858 2 15.7002V6.99996C2 6.60477 2.23273 6.24665 2.59386 6.08615L10.7816 2.44716Z" 
        fill={props.svgColor}
      />
    </svg>
  )
};

export default BlockIcon;
import React from 'react';

const LogoutIcon = (props) => {

  return (
    <svg 
      width={props.svgSize}
      height={props.svgSize}
      viewBox="0 0 24 24" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        fill-rule="evenodd" 
        clip-rule="evenodd" 
        d="M5 6C5 5.44772 5.44772 5 6 5H10C10.5523 5 11 4.55228 11 4C11 3.44772 10.5523 3 10 3H6C4.34315 3 3 4.34315 3 6V18C3 19.6569 4.34315 21 6 21H10C10.5523 21 11 20.5523 11 20C11 19.4477 10.5523 19 10 19H6C5.44772 19 5 18.5523 5 18V6ZM7 12C7 12.5523 7.44772 13 8 13L17.0858 13L13.7929 16.2929C13.4024 16.6834 13.4024 17.3166 13.7929 17.7071C14.1834 18.0976 14.8166 18.0976 15.2071 17.7071L20.2071 12.7071C20.3946 12.5196 20.5 12.2652 20.5 12C20.5 11.7348 20.3946 11.4804 20.2071 11.2929L15.2071 6.29289C14.8166 5.90237 14.1834 5.90237 13.7929 6.29289C13.4024 6.68342 13.4024 7.31658 13.7929 7.70711L17.0858 11L8 11C7.44772 11 7 11.4477 7 12Z"
        fill={props.svgColor}
      />
    </svg>
  )
};

export default LogoutIcon;
import React from 'react';

const WebIcon = (props) => {

  return (
    <svg 
      width={props.svgSize}
      height={props.svgSize}
      viewBox="0 0 24 24" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        fill-rule="evenodd" 
        clip-rule="evenodd" 
        d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z" 
        fill={props.svgColor}
      />
      <path 
        fill-rule="evenodd" 
        clip-rule="evenodd" 
        d="M10.0854 6.04218C9.43054 7.51558 9 9.62114 9 12C9 14.3789 9.43054 16.4844 10.0854 17.9578C10.4134 18.6958 10.778 19.2311 11.1324 19.5683C11.4816 19.9007 11.7731 20 12 20C12.2269 20 12.5184 19.9007 12.8676 19.5683C13.222 19.2311 13.5866 18.6958 13.9146 17.9578C14.5695 16.4844 15 14.3789 15 12C15 9.62114 14.5695 7.51558 13.9146 6.04218C13.5866 5.30422 13.222 4.76892 12.8676 4.43166C12.5184 4.0993 12.2269 4 12 4C11.7731 4 11.4816 4.0993 11.1324 4.43166C10.778 4.76892 10.4134 5.30422 10.0854 6.04218ZM9.75363 2.98287C10.3615 2.40438 11.1223 2 12 2C12.8777 2 13.6385 2.40438 14.2464 2.98287C14.8491 3.55645 15.3464 4.33918 15.7422 5.2299C16.5351 7.01386 17 9.40829 17 12C17 14.5917 16.5351 16.9861 15.7422 18.7701C15.3464 19.6608 14.8491 20.4435 14.2464 21.0171C13.6385 21.5956 12.8777 22 12 22C11.1223 22 10.3615 21.5956 9.75363 21.0171C9.15092 20.4435 8.65364 19.6608 8.25776 18.7701C7.46489 16.9861 7 14.5917 7 12C7 9.40829 7.46489 7.01386 8.25776 5.2299C8.65364 4.33918 9.15092 3.55645 9.75363 2.98287Z" 
        fill={props.svgColor}
      />
      <path 
        fill-rule="evenodd" 
        clip-rule="evenodd" 
        d="M21 13H3V11H21V13Z" 
        fill={props.svgColor}
      />
    </svg>
  )
};

export default WebIcon;
import React from 'react';

const HashIcon = (props) => {

  return (
    <svg 
      width={props.svgSize}
      height={props.svgSize}
      viewBox="0 0 24 24" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        fill-rule="evenodd" 
        clip-rule="evenodd" 
        d="M9.12403 3.00783C9.67205 3.07633 10.0608 3.57612 9.99228 4.12414L9.63278 7.00011H15.6172L16.0077 3.87607C16.0762 3.32805 16.576 2.93932 17.124 3.00783C17.6721 3.07633 18.0608 3.57612 17.9923 4.12414L17.6328 7.00011H20C20.5523 7.00011 21 7.44782 21 8.00011C21 8.55239 20.5523 9.00011 20 9.00011H17.3828L16.6328 15.0001H20C20.5523 15.0001 21 15.4478 21 16.0001C21 16.5524 20.5523 17.0001 20 17.0001H16.3828L15.9923 20.1241C15.9238 20.6722 15.424 21.0609 14.876 20.9924C14.3279 20.9239 13.9392 20.4241 14.0077 19.8761L14.3672 17.0001H8.38278L7.99228 20.1241C7.92378 20.6722 7.42399 21.0609 6.87597 20.9924C6.32795 20.9239 5.93922 20.4241 6.00772 19.8761L6.36722 17.0001H4C3.44772 17.0001 3 16.5524 3 16.0001C3 15.4478 3.44772 15.0001 4 15.0001H6.61722L7.36722 9.00011H4C3.44772 9.00011 3 8.55239 3 8.00011C3 7.44782 3.44772 7.00011 4 7.00011H7.61722L8.00772 3.87607C8.07622 3.32805 8.57601 2.93932 9.12403 3.00783ZM9.38278 9.00011L8.63278 15.0001H14.6172L15.3672 9.00011H9.38278Z" 
        fill={props.svgColor}
      />
    </svg>
  )
};

export default HashIcon;